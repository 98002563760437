import React from 'react';
import {
  MDBTabPane,
  MDBTabContent,
  MDBInput,
  MDBBtn,
  MDBNav,
  MDBNavItem,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
} from 'mdbreact';
import agent from '../../agent';
import { connect } from 'react-redux';
import {
  LOGIN_PAGE_UNLOADED,
  LOGOUT,
  KITFORMDATA,
} from '../../constants/actionTypes';
import Loader from '../../components/loaders/Loader';
import styled from '@emotion/styled';
import siteLogo from '../../shared/img/logo.png';
import CustomButton from '../../components/Buttons/CustomButton';
import SearchSection from '../Settings/Components/CompatibleReplacements/SearchSection';
import { loadKitListFromKitFinder } from '../../redux/actions/kitFinder';

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
  onKitFormData: (data) => dispatch({ type: KITFORMDATA, payload: data }),
  onKitSearch: (value) =>
    dispatch(
      loadKitListFromKitFinder({
        search: value,
        pageSize: 12,
        page: 0,
        tags: '',
        components: '',
      }),
    ),
  onClickLogout: () => dispatch({ type: LOGOUT }),
});

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  & > img {
    width: 200px;
  }

  & > div:first-of-type {
    border-top: 2px solid #1d265b;
    margin-top: 5px;
    padding-top: 10px;
    width: 200px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 95%;
  border-radius: 0;
  align-self: center;
  margin: 4px auto 20px auto;
  padding: 20px;
  min-height: calc(100% - 24px);
`;

const Description = styled.div`
  font-size: 12px;
  color: #1d265b;
  font-weight: 600;
  white-space: pre-line;
  width: 250px;
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
`;

const BaseKitInfo = styled.div`
  color: #1e275c;
  padding: 20px;
  font-weight: 600;
  font-size: 26px;

  & > a {
    display: block;
    color: #1e275c;
    text-align: center;
    opacity: 0.7;
    font-size: 14px;
    text-decoration-line: underline;

    &:hover {
      opacity: 1;
    }
  }
`;

const mapStateToProps = (state) => {
  return { currentUser: state.auth.currentUser, kits: state.kitFinder.kits };
};

let CustomKitArray = {
  components: [
    {
      id: 0,
      number: null,
      description: null,
      labelName: null,
      quantity: null,
      imageUrl: 'Please add components',
    },
  ],
};

let i = 0;
let tempAccountArray = [1];
let initializedKitFormData = {};

class KitMaker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regionalManager: null,
      contactTitle: null,
      name: null,
      contactName: null,
      salesRep: null,
      accountNumber: null,
      facilityName: null,
      facilityStreet: null,
      facilitySuite: null,
      facilityCity: null,
      facilityZipCode: null,
      isNewBusiness: false,
      competitor: null,
      currentPrice: 0,
      requestedPrice: null,
      dealerPrice: null,
      estUsage: null,
      purchaseType: null,
      jitName: null,
      hospital: null,
      accounts: [],
      activeItem: '1',
      activeDirectorItem: '1',
      blue: true,
      customKits: null,
      reloadKey: Math.random(),
      kitIdFromBase: null,
      SKU: '',
      replacedKit: null,
      kitDetail: null,
      baseKitId: null,
      productFamilyToggle: null,
      productFamily: null,
      productFamilyId: '',
      orderedKitid: null,
      isSubmitSuccess: true,
      showName: true,
      isDirectorPurchase: true,
      isBackButton: false,
      justCreatedkitId: null,
      isFavourite: 0,
      purchaseDistributor: true,
      showJitName: false,
    };
    initializedKitFormData = CustomKitArray;
    this.submitForm = this.submitForm.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.accountValue = this.accountValue.bind(this);
  }

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      });
      this.setState({ blue: !this.state.blue });
    }

    this.setState({ isNewBusiness: tab === '2' });
  };

  toggleDirectPurchase = (tab) => (e) => {
    if (this.state.activeDirectorItem !== tab) {
      this.setState({
        activeDirectorItem: tab,
        isDirectorPurchase: !this.state.isDirectorPurchase,
      });
      this.setState({ Purchaseblue: !this.state.blue });
    }
    if (tab === '1') {
      this.setState({ purchaseDistributor: false, showJitName: false });
    } else {
      this.setState({ purchaseDistributor: true });
    }

    this.setState({ isDirectorPurchase: tab === '1' });
  };

  componentDidMount() {
    let Id = window.location.href.substring(
      window.location.href.lastIndexOf('/') + 1,
    );
    const urlParams = new URLSearchParams(window.location.search);
    const KitId = urlParams.get('kitId');
    const orderedKitId = urlParams.get('orderedKitId');
    const isFavourite = urlParams.get('isFavourite');
    const token = window.localStorage.getItem('jwt');
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    if (token) {
      if (Id > 0) {
        agent.KITCATLOG.kitbyId(Id).then((res) => {
          this.setState({ customKits: res, kitDetail: res });
          let tempSKU = `${res.number}_${formattedDate}`;
          this.setState({ name: res.number, SKU: tempSKU });
          this.setState({ kitIdFromBase: Id });
          this.setState({ reloadKey: Math.random() });
        });
      } else if (orderedKitId > 0) {
        agent.KITMAKER.displaynewComponent(orderedKitId).then((res) => {
          this.setState({ customKits: res, kitDetail: res });
          let tempSKU = `${res.name}_${formattedDate}`;
          this.setState({
            name: res.name,
            SKU: tempSKU,
            orderedKitid: orderedKitId,
            reloadKey: Math.random(),
            regionalManager: res.regionalManager,
            contactTitle: res.contactTitle,
            contactName: res.contactName,
            salesRep: res.salesRep,
            facilityName: res.facilityName,
            facilityStreet: res.facilityStreet,
            facilitySuite: res.facilitySuite,
            facilityCity: res.facilityCity,
            replacedKit: res.replacedKit,
            requestedPrice: res.requestedPrice,
            facilityZipCode: res.facilityZipCode,
            competitor: res.competitor,
            currentPrice: res.currentPrice,
            dealerPrice: res.dealerPrice,
            estUsage: res.estUsage,
            purchaseType: res.isDirectPurchase,
            jitName: res.jitName,
            hospital: res.hospital,
            accountNumber: res.accountNumber,
            productFamilyId: res.productFamilyId,
            activeItem: res.isNewBusiness ? '2' : '1',
            isNewBusiness: res.isNewBusiness,
            isDirectorPurchase: res.isDirectPurchase,
            activeDirectorItem: res.isDirectPurchase ? '1' : '2',
          });
        });
      } else if (KitId > 0) {
        agent.KITMAKER.displaynewComponent(KitId).then((res) => {
          this.setState({ kitDetail: res });
          let tempSKU = `${res.name}_${formattedDate}`;
          this.setState({
            name: res.name,
            SKU: tempSKU,
            kitIdFromBase: KitId,
            reloadKey: Math.random(),
            regionalManager: res.regionalManager,
            contactTitle: res.contactTitle,
            contactName: res.contactName,
            salesRep: res.salesRep,
            facilityName: res.facilityName,
            facilityStreet: res.facilityStreet,
            facilitySuite: res.facilitySuite,
            facilityCity: res.facilityCity,
            facilityZipCode: res.facilityZipCode,
            competitor: res.competitor,
            replacedKit: res.replacedKit,
            currentPrice: res.currentPrice,
            requestedPrice: res.requestedPrice,
            dealerPrice: res.dealerPrice,
            estUsage: res.estUsage,
            purchaseType: res.purchaseType,
            jitName: res.jitName,
            hospital: res.hospital,
            activeItem: res.isNewBusiness ? '2' : '1',
            isNewBusiness: res.isNewBusiness,
            isDirectorPurchase: res.isDirectPurchase,
            activeDirectorItem: res.isDirectPurchase ? '1' : '2',
          });
        });
      } else {
        if (window.performance) {
          if (performance.navigation.type === 1) {
            let tempdata = `${initializedKitFormData.name}_${formattedDate}`;
            this.setState({
              SKU: tempdata ? tempdata : ' ',
              kitDetail: initializedKitFormData,
              name: initializedKitFormData.kitName,
              regionalManager: initializedKitFormData.regionalManager,
              contactTitle: initializedKitFormData.contactTitle,
              contactName: initializedKitFormData.contactName,
              salesRep: initializedKitFormData.salesRep,
              facilityName: initializedKitFormData.facilityName,
              facilityStreet: initializedKitFormData.facilityStreet,
              facilitySuite: initializedKitFormData.facilitySuite,
              facilityCity: initializedKitFormData.facilityCity,
              replacedKit: initializedKitFormData.replacedKit,
              facilityZipCode: initializedKitFormData.facilityZipCode,
              competitor: initializedKitFormData.competitor,
              currentPrice: initializedKitFormData.currentPrice,
              requestedPrice: initializedKitFormData.requestedPrice,
              dealerPrice: initializedKitFormData.dealerPrice,
              estUsage: initializedKitFormData.estUsage,
              purchaseType: initializedKitFormData.purchaseType,
              jitName: initializedKitFormData.jitName,
              hospital: initializedKitFormData.hospital,
              justCreatedkitId: initializedKitFormData.Id,
            });
          } else {
            this.setState({ kitDetail: CustomKitArray });
          }
        }
      }

      if (isFavourite > 0) {
        this.setState({ isFavourite: isFavourite });
      }

      agent.KITMAKER.productFamily().then((res) => {
        this.setState({
          productFamilyToggle: !this.state.productFamilyToggle,
          productFamily: res,
        });
      });
    } else {
      this.props.onClickLogout();
    }
  }

  validateName = (name, kitid) => {
    agent.KITMAKER.validateName(name, kitid).then((res) => {
      if (res === true) {
        this.setState({ showName: true });
      } else {
        this.setState({ showName: false });
        window.scroll(0, 0);
      }
    });
  };

  handleChange = (event) => {
    let kitData = {
      name: '',
      value: '',
    };
    switch (event.target.name) {
      case 'kitName':
        if (this.state.orderedKitid > 0) {
          this.validateName(event.target.value, null);
        } else if (this.state.isBackButton === true) {
          this.validateName(event.target.value, null);
        } else {
          this.validateName(event.target.value, null);
        }
        this.setState({ name: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'replacedKit':
        this.setState({ replacedKit: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'requestedPrice':
        this.setState({ requestedPrice: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'regionalManager':
        this.setState({ regionalManager: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'contactTitle':
        this.setState({ contactTitle: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'contactName':
        this.setState({ contactName: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'salesRep':
        this.setState({ salesRep: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'accountNumber':
        this.setState({ accountNumber: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'facilityName':
        this.setState({ facilityName: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;

      case 'facilityStreet':
        this.setState({ facilityStreet: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'facilitySuite':
        this.setState({ facilitySuite: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'facilityCity':
        this.setState({ facilityCity: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;

      case 'facilityZipCode':
        this.setState({ facilityZipCode: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;

      case 'isNewBusiness':
        this.setState({ isNewBusiness: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'competitor':
        this.setState({ competitor: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'competitorPrice':
        this.setState({
          currentPrice: event.target.value === '' ? '0' : event.target.value,
        });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'currentPrice':
        this.setState({
          currentPrice: event.target.value === '' ? '0' : event.target.value,
        });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      // case "requestedPrice":
      //     this.setState({ requestedPrice: event.target.value });
      //     kitData.name = event.target.name;
      //     kitData.value = event.target.value;
      //     this.props.onKitFormData(kitData);
      //     break;
      case 'estUsage':
        this.setState({ estUsage: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'purchaseType':
        this.setState({ purchaseType: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'jitName':
        this.setState({ jitName: event.target.value });
        this.setState({ showJitName: false });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;
      case 'productFamilyId':
        this.setState({ productFamilyId: event.target.value });
        kitData.name = event.target.name;
        kitData.value = event.target.value;
        this.props.onKitFormData(kitData);
        break;

      default:
        break;
    }
  };

  submitForm(ev) {
    let name = null;
    ev.preventDefault();
    //  const urlParams = new URLSearchParams(window.location.search);
    //   const KitId = urlParams.get('kitId');
    tempAccountArray.forEach((element, i) => {
      this.state.accounts.push(document.getElementById('account' + i).value);
    });
    this.setState({ accounts: this.state.accounts });
    //        const token = window.sessionStorage.getItem('jwt');
    if (this.state.competitor !== null || this.state.currentPrice !== null) {
      this.setState({ isNewBusiness: true });
    } else {
      this.setState({ isNewBusiness: false });
    }
    if (this.state.name == null) {
      name = 'CUSTOMIZED-KIT';
    } else {
      name = this.state.name;
    }
    let formData = {
      name: name,
      regionalManager: this.state.regionalManager,
      contactTitle: this.state.contactTitle,
      contactName: this.state.contactName,
      salesRep: this.state.salesRep,
      accountNumber: this.state.accountNumber,
      facilityName: this.state.facilityName,
      facilityStreet: this.state.facilityStreet,
      facilityCity: this.state.facilityCity,
      facilitySuite: this.state.facilitySuite,
      replacedKit: this.state.replacedKit,
      requestedPrice: +this.state.requestedPrice,
      facilityZipCode: this.state.facilityZipCode,
      isNewBusiness: this.state.isNewBusiness,
      competitor: this.state.competitor,
      currentPrice:
        this.state.currentPrice !== null && this.state.currentPrice !== ''
          ? +this.state.currentPrice
          : 0,
      estUsage: +this.state.estUsage,
      isDirectPurchase: this.state.isDirectorPurchase,
      purchaseType: this.state.purchaseType,
      jitName: this.state.purchaseDistributor ? this.state.jitName : null,
      accounts: this.state.accounts,
      baseKitId: +this.state.kitIdFromBase,
      productFamilyId: +this.state.productFamilyId,
      baseCustomKitId: this.state.orderedKitid
        ? +this.state.orderedKitid
        : this.state.orderedKitid,
    };

    this.setState({ isSubmitSuccess: false });
    if (this.state.orderedKitid > 0) {
      this.validateName(name, null);
    } else if (this.state.isBackButton === true) {
      this.validateName(name, null);
    } else {
      this.validateName(name, null);
    }
    if (
      (this.state.purchaseDistributor === true &&
        this.state.jitName !== '' &&
        this.state.jitName !== null) ||
      this.state.isDirectorPurchase === true
    ) {
      let kitData = {};
      agent.KITMAKER.customKits(formData).then((res) => {
        if (res.statusCode === 400) {
          this.setState({ showName: true });
        } else {
          this.setState({ isSubmitSuccess: true, isBackButton: false });
          this.setState({ customKits: res });
          kitData.name = 'kitId';
          kitData.value = res.id;
          this.props.onKitFormData(kitData);
          this.props.history.push(
            '/kitmakercomponents?KitFromBase=' +
              this.state.kitIdFromBase +
              '&kit=' +
              name +
              '&kitId=' +
              res.id +
              '&orederedKitId=' +
              this.state.orderedKitid +
              '&isFavourite=' +
              this.state.isFavourite,
          );
        }
      });
    } else if (this.state.purchaseDistributor === false) {
      let kitData = {};
      agent.KITMAKER.customKits(formData).then((res) => {
        if (res.statusCode === 400) {
          this.setState({ showName: true });
        } else {
          this.setState({ isSubmitSuccess: true, isBackButton: false });
          this.setState({ customKits: res });
          kitData.name = 'kitId';
          kitData.value = res.id;
          this.props.onKitFormData(kitData);
          this.props.history.push(
            '/kitmakercomponents?KitFromBase=' +
              this.state.kitIdFromBase +
              '&kit=' +
              name +
              '&kitId=' +
              res.id +
              '&orederedKitId=' +
              this.state.orderedKitid +
              '&isFavourite=' +
              this.state.isFavourite,
          );
        }
      });
    } else {
      this.setState({ showJitName: true });
    }
  }

  accountValue = () => {
    tempAccountArray.push(document.getElementById('account' + i).value);
    if (document.getElementById('account' + i).value !== '') {
      i = i + 1;
      let Id = 'account' + i;
      var node = document.createElement('input');
      var nodeIcon = document.createElement('span');
      nodeIcon.setAttribute('className', 'addicon');
      nodeIcon.setAttribute('value', '+');
      var textnode = document.createTextNode('+');
      node.setAttribute('type', 'text');
      node.setAttribute('value', '');
      node.setAttribute('Id', Id);
      node.setAttribute('name', 'Test Name');
      node.setAttribute('class', 'form-control boxinput');
      node.setAttribute('min', '0');
      node.setAttribute('title', 'Enter Alphanumeric Values');
      //node.setAttribute("inputMode", "numeric");
      node.setAttribute('pattern', '[A-Za-z0-9_]+$');
      node.appendChild(textnode);
      node.appendChild(nodeIcon);
      document.getElementById('accountDiv').appendChild(node);
    }
  };
  changeColor = () => {
    this.setState({ blue: !this.state.blue });
  };

  selectKitHandler = (obj) => {
    this.props.history.push('/kitmaker/' + obj.id);
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    agent.KITCATLOG.kitbyId(obj.id).then((res) => {
      this.setState({
        customKits: res,
        kitDetail: res,
      });
      let tempSKU = `${res.number}_${formattedDate}`;
      this.setState({ name: res.number, SKU: tempSKU });
      this.setState({ kitIdFromBase: obj.id });
      this.setState({ reloadKey: Math.random() });
    });
    this.setState({ customKits: obj });
  };

  render() {
    if (this.state.kitDetail === null || this.state.productFamily === null) {
      return <Loader />;
    }
    if (this.state.customKits === null) {
      return (
        <div className="main-layout">
          <div className="content-area" style={{ paddingBottom: 0 }}>
            <div
              style={{
                display: 'flex',
                height: 'calc(100vh - 65px)',
                overflow: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Wrapper>
                  <Content>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <LogoSection>
                        <img src={siteLogo} alt="logo" />
                        <Description>
                          Search for a kit to use as base
                        </Description>
                        <SearchSection
                          offset={600}
                          components={this.props.kits || []}
                          onSearch={() => {}}
                          onSearchChange={(e) =>
                            this.props.onKitSearch(e.target.value)
                          }
                          itemColor="black"
                          fancyStyle={{
                            color: 'gray',
                            backcolor: 'unset',
                            width: '100%',
                          }}
                          placeholder="Kit SKU Search"
                          assignComponent={this.selectKitHandler}
                          placeholderStyle={{
                            textAlign: 'center',
                            textIndent: 'unset',
                            color: '#1d265b',
                            fontWeight: 600,
                          }}
                        />
                        <Description style={{ fontWeight: 500 }}>
                          Or use the kit catalog:
                        </Description>
                        <CustomButton
                          label="Kit Catalog"
                          background={`#61A431`}
                          rounded
                          style={{
                            fontSize: 20,
                            fontWeight: 600,
                            paddingLeft: 40,
                            paddingRight: 40,
                          }}
                          onClick={() =>
                            this.props.history.push('/kit-catalog')
                          }
                        />
                      </LogoSection>
                    </div>
                  </Content>
                </Wrapper>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const data = this.state.kitDetail;
      var productFamily = '';
      productFamily = this.state.productFamily.map((detail) => {
        return (
          <option key={detail.id} value={detail.id}>
            {detail.name}
          </option>
        );
      });

      const alertName = (
        <span className="warntext">
          Name already exists.Please choose another name for your Custom Kit.
        </span>
      );
      const alertJitName = (
        <span className="warntext">Please fill this field.</span>
      );

      return (
        <>
          {/* <Beforeunload onBeforeunload={() => "You'll lose your data!"} /> */}
          <div className="main-layout">
            <div className="content-area">
              <MDBContainer fluid>
                <h5 className="mt-5 mt-sm-5 mt-lg-0 mt-md-0">
                  Arrow select products request form
                </h5>
                <MDBRow>
                  <MDBCol md="12">
                    <MDBCard className="kitmaker mt-0">
                      <MDBCardBody className="p30">
                        <form onSubmit={this.submitForm}>
                          <MDBRow>
                            <MDBCol md="4">
                              <BaseKitInfo>
                                Base Kit : {this.state.customKits.number}
                                <a href="/kit-catalog">Change Base Kit</a>
                              </BaseKitInfo>
                            </MDBCol>
                            <MDBCol md="4" mt="2">
                              <p></p>
                              <br />
                              <select
                                className="browser-default custom-select selectOptions"
                                name="productFamilyId"
                                required
                                onChange={this.handleChange}
                                value={this.state.productFamilyId}
                              >
                                <option value="">Product Family</option>
                                {productFamily}
                              </select>
                            </MDBCol>
                            <MDBCol md="4">
                              <MDBInput
                                label="What would you like to save this kit request in your profile as ? *"
                                type="text"
                                autoFocus={true}
                                valueDefault={this.state.SKU}
                                key={this.state.reloadKey}
                                onBlur={this.handleChange}
                                onChange={this.handleChange}
                                name="kitName"
                                required
                              />

                              {this.state.showName === false ? alertName : null}
                            </MDBCol>
                          </MDBRow>
                          <section id="authentication-layout">
                            <MDBRow>
                              <MDBCol lg="6" md="12" className="pt-1">
                                <MDBRow className="mb-9">
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="Regional Manager *"
                                      type="text"
                                      name="regionalManager"
                                      title="Enter only letters"
                                      pattern="^[A-Za-z -]+$"
                                      valueDefault={data.regionalManager}
                                      onChange={this.handleChange}
                                      className="form-control"
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="Sales Representative *"
                                      type="text"
                                      name="salesRep"
                                      title=""
                                      valueDefault={
                                        data.salesRep ? data.salesRep : ''
                                      }
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="Purchasing Contact Title *"
                                      type="text"
                                      name="contactTitle"
                                      title="Enter only letters"
                                      pattern="^[A-Za-z -]+$"
                                      valueDefault={data.contactTitle}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    {' '}
                                    <MDBInput
                                      label="Purchasing Contact *"
                                      type="text"
                                      name="contactName"
                                      title="Enter only letters"
                                      pattern="^[A-Za-z -]+$"
                                      valueDefault={data.contactName}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="12">
                                    {' '}
                                    <MDBInput
                                      label="Account Number *"
                                      type="text"
                                      title="Enter only Alphanumeric Values"
                                      pattern="^[A-Za-z0-9_]+$"
                                      name="accountNumber"
                                      valueDefault={data.accountNumber}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>

                                  {/* <MDBCol md="12"><MDBInput label="Facility Name and Address *" type="text" name="facility" title="" valueDefault={data.facility} onChange={this.handleChange} required /></MDBCol> */}
                                  <MDBCol md="12">
                                    <span>Facilty Name And Address</span>
                                  </MDBCol>
                                  <MDBCol md="12">
                                    <MDBInput
                                      label="Facility Name *"
                                      type="text"
                                      name="facilityName"
                                      title="Enter only characters"
                                      maxLength="50"
                                      pattern="^[A-Za-z -]+$"
                                      valueDefault={data.facilityName}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <MDBInput
                                      label="Street *"
                                      type="text"
                                      name="facilityStreet"
                                      title="Special charcters are not allowed except Coma (,)"
                                      pattern="^[A-Za-z0-9, _]*[A-Za-z0-9,][A-Za-z0-9, _]*$"
                                      valueDefault={data.facilityStreet}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <MDBInput
                                      label="Suite/Apt "
                                      type="text"
                                      name="facilitySuite"
                                      title="Special charcters are not allowed"
                                      pattern="^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                      valueDefault={data.facilitySuite}
                                      onChange={this.handleChange}
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <MDBInput
                                      label="City, State *"
                                      type="text"
                                      name="facilityCity"
                                      title="Format is City, State (Chicago, Illinois)"
                                      pattern="^[A-Z a-z]*,[ A-Za-z][A-Za-z]*$"
                                      valueDefault={data.facilityCity}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="6">
                                    <MDBInput
                                      label="ZipCode *"
                                      type="text"
                                      name="facilityZipCode"
                                      title="Special charcters are not allowed"
                                      pattern="^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _]*$"
                                      valueDefault={data.facilityZipCode}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol md="12" className="text-center">
                                    <p className="info">
                                      Add account from sister hospital using
                                      Custom Kits (if applicable)
                                    </p>
                                  </MDBCol>
                                  <MDBCol
                                    md="6"
                                    className="text-center offset-md-3"
                                  >
                                    <label
                                      htmlFor="defaultFormLoginEmailEx"
                                      className="grey-text"
                                    >
                                      {' '}
                                      Subsidiary Account Number
                                      <span
                                        data-placement="right"
                                        data-toggle="tooltip"
                                        className="tooltips"
                                      >
                                        <span className="tooltipstext">
                                          <i
                                            className="fa fa-info"
                                            aria-hidden="true"
                                          ></i>
                                          <span>ACCOUNT Number</span>
                                          <br />
                                        </span>
                                      </span>{' '}
                                    </label>
                                    <div id="accountDiv">
                                      <input
                                        type="text"
                                        id="account0"
                                        className="form-control boxinput "
                                        value={this.state.accounts[0]}
                                        title="Enter only Alphanumeric Values"
                                        pattern="^[A-Za-z0-9_]+$"
                                      />
                                      <span
                                        className="addicon"
                                        value="1"
                                        onClick={() => this.accountValue()}
                                      >
                                        +
                                      </span>
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                              <MDBCol lg="6" md="12" className="pt-1 pr0">
                                <MDBRow className="mb-9 text-center">
                                  <MDBCol
                                    md="10"
                                    className="offset-md-1 p-xs-0"
                                  >
                                    <h6>The ASK product is</h6>
                                    <MDBCol md="12" className="p-xs-0">
                                      <MDBNav className="nav-tabs mt-4">
                                        <MDBNavItem></MDBNavItem>
                                        <MDBNavItem></MDBNavItem>
                                      </MDBNav>
                                      <div className="btn-section mtb2 tab-area">
                                        <MDBBtn
                                          className="mb20-xs btn-whitee"
                                          active={this.state.activeItem === '1'}
                                          onClick={this.toggle('1')}
                                        >
                                          {' '}
                                          Replacing Existing Arrow Business
                                        </MDBBtn>
                                        <MDBBtn
                                          className="mb20-xs btn-outline-indigo notwhite"
                                          active={this.state.activeItem === '2'}
                                          onClick={this.toggle('2')}
                                        >
                                          {' '}
                                          New Business
                                        </MDBBtn>
                                      </div>
                                      <MDBTabContent
                                        activeItem={this.state.activeItem}
                                      >
                                        <MDBTabPane tabId="1" role="tabpanel">
                                          <MDBCol md="12">
                                            {' '}
                                            <MDBInput
                                              label="Kit Being Replaced: *"
                                              type="text"
                                              name="replacedKit"
                                              min="0"
                                              title="Enter only numbers"
                                              valueDefault={data.replacedKit}
                                              onChange={this.handleChange}
                                              required={
                                                this.state.activeItem === '1'
                                              }
                                            />
                                          </MDBCol>
                                          <MDBCol md="12">
                                            {' '}
                                            <MDBInput
                                              label="Current Price ($): *"
                                              type="text"
                                              name="currentPrice"
                                              min="0"
                                              title="Enter only numbers(or with Decimals)"
                                              inputMode="numeric"
                                              pattern="^(\d*\.)?\d+$"
                                              valueDefault={data.currentPrice}
                                              onChange={this.handleChange}
                                              required={
                                                this.state.activeItem === '1'
                                              }
                                            />
                                          </MDBCol>
                                        </MDBTabPane>
                                        <MDBTabPane tabId="2" role="tabpanel">
                                          <MDBCol md="12">
                                            {' '}
                                            <MDBInput
                                              label="Current Competitor:"
                                              type="text"
                                              name="competitor"
                                              valueDefault={data.competitor}
                                              onChange={this.handleChange}
                                            />
                                          </MDBCol>
                                          <MDBCol md="12">
                                            {' '}
                                            <MDBInput
                                              label="Competitor's Price ($):"
                                              type="text"
                                              min="0"
                                              title="Enter only numbers(or with Decimals)"
                                              inputMode="numeric"
                                              pattern="^(\d*\.)?\d+$"
                                              name="competitorPrice"
                                              valueDefault={data.currentPrice}
                                              onChange={this.handleChange}
                                            />
                                          </MDBCol>
                                        </MDBTabPane>
                                      </MDBTabContent>
                                    </MDBCol>
                                  </MDBCol>
                                  {/* <MDBCol md="12" className="mt-4">   <h6>Request pricing for new product</h6></MDBCol> */}
                                  <MDBCol md="12">
                                    {' '}
                                    <MDBInput
                                      label="Requested price ($):"
                                      type="text"
                                      name="requestedPrice"
                                      min="0"
                                      title="Enter only numbers(or with Decimals)"
                                      inputMode="numeric"
                                      pattern="^(\d*\.)?\d+$"
                                      valueDefault={data.requestedPrice}
                                      onChange={this.handleChange}
                                    />
                                  </MDBCol>
                                  <MDBCol md="12">
                                    {' '}
                                    <MDBInput
                                      label="Estimated Annual Usage in Eaches *"
                                      type="text"
                                      name="estUsage"
                                      min="0"
                                      title="Enter only numbers"
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      valueDefault={data.estUsage}
                                      onChange={this.handleChange}
                                      required
                                    />
                                  </MDBCol>
                                  <MDBCol
                                    md="10"
                                    className="offset-md-1 p-xs-0"
                                  >
                                    <MDBNav className="nav-tabs mt-4">
                                      <MDBNavItem></MDBNavItem>
                                      <MDBNavItem></MDBNavItem>
                                    </MDBNav>
                                    <div className="btn-section mtb2 tab-area">
                                      <MDBBtn
                                        className="mb20-xs btn-whitee"
                                        active={
                                          this.state.activeDirectorItem === '1'
                                        }
                                        onClick={this.toggleDirectPurchase('1')}
                                        value={this.state.isDirectorPurchase}
                                      >
                                        {' '}
                                        Purchase Type is Direct{' '}
                                      </MDBBtn>
                                      <MDBBtn
                                        className="mb20-xs btn-outline-indigo notwhite"
                                        active={
                                          this.state.activeDirectorItem === '2'
                                        }
                                        onClick={this.toggleDirectPurchase('2')}
                                        value={this.state.purchaseDistributor}
                                      >
                                        Purchase Type is Distributor
                                      </MDBBtn>
                                    </div>
                                    <MDBTabContent
                                      activeItem={this.state.activeDirectorItem}
                                    >
                                      <MDBTabPane
                                        tabId="1"
                                        role="tabpanel"
                                      ></MDBTabPane>
                                      <MDBTabPane tabId="2" role="tabpanel">
                                        <MDBCol md="12">
                                          <MDBInput
                                            label="Distributor Name"
                                            id="distributorname"
                                            type="text"
                                            name="jitName"
                                            title=""
                                            valueDefault={data.jitName}
                                            onChange={this.handleChange}
                                          />
                                        </MDBCol>
                                        {this.state.showJitName === true
                                          ? alertJitName
                                          : null}
                                      </MDBTabPane>
                                    </MDBTabContent>
                                  </MDBCol>
                                </MDBRow>
                              </MDBCol>
                            </MDBRow>
                            <div className="btn-area">
                              <MDBRow className="text-right btn-section">
                                <MDBCol>
                                  <MDBBtn
                                    className="gbtn  mt-5"
                                    type="submit"
                                    value="submit"
                                  >
                                    Continue
                                  </MDBBtn>
                                </MDBCol>
                              </MDBRow>
                            </div>
                          </section>
                        </form>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </div>
          </div>
        </>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KitMaker);
