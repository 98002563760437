import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import siteLogo from '../../shared/img/logo.png';
import { MDBInput, MDBBtn } from 'mdbreact';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { login } from '../../redux/actions/auth';
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';
import MicrosoftSSOLogin from './MicrosoftSSOLogin';
import { useDispatch } from 'react-redux';

const Link = styled.a`
  color: white;
  display: block;
  padding-top: 50px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
    color: white;
  }
`;

const Login = () => {
  const { isAuthenticated, loginLoading } = useSelector((state) => state.auth);
  const ref = useRef();

  const [autoFill, setAutoFill] = useState(false);

  const [user, setUser] = useState({
    userName: '',
    password: '',
  });

  const dispatch = useDispatch();

  const handleChange = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(user.userName, user.password));
  };

  // Chrome issue. https://bugs.chromium.org/p/chromium/issues/detail?id=1166619
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.inputElementRef.current.addEventListener(
        'animationstart',
        (e) => {
          if (e.animationName === 'onAutoFillStart') {
            setAutoFill(true);
          }
        },
      );
    }
  }, [ref]);

  return (
    <>
      {!isAuthenticated ? (
        <div className="App">
          <section id="authentication-layout">
            <div className="flex-container">
              <div className="img-area">
                <img src={siteLogo} alt="logo" style={{ width: '50%' }} />
              </div>
              <div className="authentication-area">
                <form onSubmit={onSubmit} style={{ width: '100%' }}>
                  <MDBInput
                    ref={ref}
                    label="User"
                    id="email"
                    type="email"
                    icon="user"
                    name="userName"
                    value={user.userName}
                    onChange={handleChange}
                    color="black"
                    disabled={loginLoading}
                  />
                  <MDBInput
                    label="Password"
                    id="password"
                    type="password"
                    icon="lock"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    color="black"
                    disabled={loginLoading}
                  />
                  <MDBBtn
                    color="white"
                    type="submit"
                    disabled={
                      (Object.values(user).some((u) => !u.length) &&
                        !autoFill) ||
                      loginLoading
                    }
                  >
                    {loginLoading ? (
                      <>
                        <i
                          className="fas fa-sync fa-spin"
                          style={{ marginRight: '10px', fontSize: '14px' }}
                        ></i>
                        Please wait...
                      </>
                    ) : (
                      <>Login</>
                    )}
                  </MDBBtn>
                  {!loginLoading && (
                    <Link href="/reset-request">Reset password</Link>
                  )}
                </form>
                <MicrosoftSSOLogin />
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Redirect to="/components" />
      )}
    </>
  );
};

export default Login;
